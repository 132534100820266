<template>
    <div class="col-12">
        <template v-for="(item, key) in data">
            <table class="w-100 table table-borderless table-secondary" :key="item.id">
                <tr>
                    <th class="pb-0">
                        <label v-if="type == 0" class="mb-0" >Typová aktivita č.{{key+1}} | Aktivitätstyp Nr. {{key+1}}</label>
                      <label v-else class="mb-0" >Milník č.{{key+1}} | Meilenstein Nr. {{key+1}}</label>
                    </th>
                </tr>
              <tr>
                <td class="w-50">
                  <label>{{ (type == 0) ? 'Název typové aktivity' : 'Název milníku' }}</label>
                  <b-form-input v-model="item.name_cs"></b-form-input>
                </td>
                <td class="w-50">
                  <label>{{ (type == 0) ? 'Bezeichnung des Aktivitätstyps' : 'Bezeichnung des Meilensteines' }}</label>
                  <b-form-input v-model="item.name_de"></b-form-input>
                </td>
              </tr>
              <tr>
                <td class="w-50">
                  <label>{{ (type == 0) ? 'Popis typové aktivity, zapojení partnera, časový plán (max. 1500 znaků)' : 'Popis milníku, zapojení partnera, časový plán (max. 1500 znaků)' }}</label>
                  <b-textarea v-model="item.description_cs" max-rows="8" rows="2"></b-textarea>
                </td>
                <td class="w-50">
                  <label>{{ (type == 0) ? 'Beschreibung des Aktivitätstyps, Einbindung des Partners, Zeitplan (max. 1800 Zeichen)' : 'Beschreibung des Meilensteines, Einbindung des Partners, Zeitplan (max. 1800 Zeichen)' }}</label>
                  <b-textarea v-model="item.description_de" max-rows="8" rows="2"></b-textarea>
                </td>
              </tr>
              <tr v-if="type == 1">
                <td class="w-50">
                  <label>{{ (type == 0) ? '' : 'Výstup milníku a jeho kvantifikace (max. 200 znaků)' }}</label>
                  <b-textarea v-model="item.activities_cs" max-rows="8" rows="2"></b-textarea>
                </td>
                <td class="w-50">
                  <label>{{ (type == 0) ? '' : 'Output des Meilensteines und seine Quantifizierung (max. 300 Zeichen)' }}</label>
                  <b-textarea v-model="item.activities_de" max-rows="8" rows="2"></b-textarea>
                </td>
              </tr>
              <tr v-if="type == 1">
                <td class="w-50">
                  <label>{{ 'Podklady ke splnění milníku (max. 700 znaků)' }}
                    <span :id="'popover-proof-cs-'+item.id"><b-icon aria-hidden="true" icon="info-circle"></b-icon></span>
                    <b-popover :target="'popover-proof-cs-'+item.id" triggers="hover focus">
                      viz Příručka pro FMP bod 6.2.1.
                    </b-popover>
                  </label>
                  <b-textarea v-model="item.proof_cs" max-rows="8" rows="2"></b-textarea>
                </td>
                <td class="w-50">
                  <label>{{  'Nachweise zur Erfüllung des Meilensteines (max. 900 Zeichen)' }}
                    <span :id="'popover-proof-de'+item.id"><b-icon aria-hidden="true" icon="info-circle"></b-icon></span>
                    <b-popover :target="'popover-proof-de'+item.id" triggers="hover focus">
                      Siehe Handbuch für die KPFs Punkt 6.2.1.
                    </b-popover>
                  </label>
                  <b-textarea v-model="item.proof_de" max-rows="8" rows="2"></b-textarea>
                </td>
              </tr>
              <tr v-else>
                <td class="w-50">
                  <label>{{'Podklady ke splnění typové aktivity (max. 700 znaků)' }}
                  </label>
                  <b-textarea v-model="item.proof_cs" max-rows="8" rows="2"></b-textarea>
                </td>
                <td class="w-50">
                  <label>{{ 'Nachweise zur Erfüllung des Aktivitätstyps (max. 900 Zeichen)' }}
                  </label>
                  <b-textarea v-model="item.proof_de" max-rows="8" rows="2"></b-textarea>
                </td>
              </tr>

                <tr>
                    <td colspan="2" class="pt-0">
                        <b-button @click="deleteItem(item.id)" type="button" size="sm" variant="danger">
                            <b-icon class="clickable" icon="trash-fill" variant=""></b-icon>&nbsp;
                            {{ $t('projects.activities.removeBtn') }}
    
                        </b-button>
                    </td>
                </tr>
            </table>
        </template>
        <b-button @click="addItem()" type="button" size="sm" class="mb-3" variant="primary">
            <b-icon class="clickable" icon="plus-square-fill" variant=""></b-icon>&nbsp;
            {{ $t('projects.activities.addBtn') }}
        </b-button>

    </div>
</template>

<script>
export default {
    name: "project-activities",
    props: {
        idArticle: {
            type: Number
        },
        type: {
          type: Number
        }
    },
    data() {
        return {
            data: {},
        }
    },
    methods: {
        
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/activities/list.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    params: {
                      'id_articles': this.idArticle,
                      'type': this.type,
                    },
                    method: 'GET'
                });
                
                this.data = response.data;
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        async updateItems() {
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/activities/update-items.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {
                        'data': this.data,
                        'id_articles': this.idArticle
                    },
                    method: 'PUT'
                });
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        
        },
        async addItem() {
            await this.updateItems();
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/activities/add-item.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {
                      'id_articles': this.idArticle,
                      'type': this.type,
                    },
                    method: 'POST'
                });
                
                await this.loadData();
                
            } catch (error) {
                await this.loadData();
                console.log("error", error);
            }
            
        },
        async deleteItem(id) {
            await this.updateItems();
            try {
                if (confirm(this.$t('projects.deleteItem'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/articles/activities/remove-item.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {
                            'id': id,
                            'id_articles': this.idArticle
                        },
                        method: 'DELETE'
                    });
                    this.loadData();
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                }
                
            } catch (error) {
                await this.loadData();
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        
    },
    mounted() {
        this.loadData();
    },
}
</script>

<style lang="scss" scoped>
  .w-50 {
    width: 50%;
  }
</style>
